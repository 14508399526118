<template>
  <div
    :style="{
      height: 'var(--page-height-px)',
      minHeight: 'var(--page-height-px)'
    }"
    class="flex flex-col overflow-hidden"
  >
    <ul
      :class="[
        'w-full h-full grid overflow-hidden',
        isMobileAndTabletHorizontal ? ' grid-cols-3' : ' grid-cols-2 md:grid-cols-3'
      ]"
    >
      <li
        v-for="(car, index) in CAR_OPTIONS_LIST"
        :key="`${car.key}${index}`"
        class="relative w-full h-full grow group overflow-hidden cursor-pointer"
        @click="onSelectCar(car)"
      >
        <div
          :class="[
            'absolute top-0 left-0 z-10 w-full h-full bg-black transition-all duration-400 ease-[cubic-bezier(0.33,1,0.68,1)] opacity-60 group-hover:opacity-0',
          ]"
        />
        <img
          :src="car.img"
          :alt="car.key"
          :class="[
            'w-full h-full object-cover transition-all duration-500 ease-[cubic-bezier(0.33,1,0.68,1)] group-hover:object-[40%_center]',
          ]"
        >
        <span
          :class="[
            'absolute top-1/2 left-1/2 z-[11] -translate-x-1/2 -translate-y-1/2 text-center text-white transition-opacity duration-400 ease-[cubic-bezier(0.33,1,0.68,1)] opacity-100 group-hover:opacity-0',
          ]"
        >
          {{ car.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { useWindowSize , useScreenOrientation } from '@vueuse/core';
import { computed } from 'vue';

export default {
  setup() {
    const { orientation } = useScreenOrientation();
    const { width } = useWindowSize();
    const isMobileAndTabletHorizontal = computed(() => width < 900 && orientation.value.includes('landscape'));

    const CAR_OPTIONS_LIST = [
      {
        key: 'mercedes',
        img: require('@/assets/e_class_w214_25_1.jpg'),
        name: 'Mercedes E Class 2024',
        baseDiameter: 18,
        diameters: [18,19,20,21],
      },
      {
        key: 'smart',
        img: require('@/assets/smart.jpg'),
        name: 'Smart EQ',
        baseDiameter: 16,
        diameters: [16,17],
      },
      {
        key: 'skoda',
        img: require('@/assets/skoda-karoq.jpg'),
        name: 'Skoda Karoq Facelift',
        baseDiameter: 18,
        diameters: [18,19,20],
      },
      {
        key: 'porsche',
        img: require('@/assets/porsche-taycan.jpg'),
        name: 'Porche Taycan',
        baseDiameter: 21,
        diameters: [20,21],
      },
      {
        key: 'ford',
        img: require('@/assets/ford-ranger.jpg'),
        name: 'Ford Ranger',
        baseDiameter: 17,
        diameters: [17,18,19,20],
      },
      {
        key: 'cupra',
        img: require('@/assets/cupra-leon.jpg'),
        name: 'Cupra Formentor',
        baseDiameter: 18,
        diameters: [18,19,20],
      },
    ];
    return {
      orientation,
      width,
      isMobileAndTabletHorizontal,
      CAR_OPTIONS_LIST,
    };
  },
  methods: {
    onSelectCar(car) {
      this.$router.push({
        name: 'configurator',
        params: {
          carId: car.key,
        },
        query: {
          name: car.name,
          diameters: car.diameters.join(),
          baseDiameter: car.baseDiameter,
        },
      });
    },
  },
};
</script>