<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="2"
    viewBox="0 0 12 2"
  >
    <line
      id="Line_353"
      data-name="Line 353"
      x2="12"
      transform="translate(0 1)"
      fill="none"
      stroke="#787c80"
      stroke-width="2"
    />
  </svg>
</template>