<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="25"
    viewBox="0 0 11.145 19.853"
  >
    <g
      id="Group_3178"
      transform="translate(0.079 0.237)"
    >
      <path
        id="Path_204"
        d="M40.814,70.684h2.43a1.629,1.629,0,0,1,1.54,1.118H39.273A1.63,1.63,0,0,1,40.814,70.684Zm0,0"
        transform="translate(-35.118 -66.998)"
        fill="#787c80"
      />
      <path
        id="Path_205"
        d="M87.637.541A.541.541,0,0,1,88.178,0h1.085A.542.542,0,0,1,89.8.541v.927H87.637Zm0,0"
        transform="translate(-81.884 1.134)"
        fill="#787c80"
      />
      <path
        id="Path_206"
        d="M104.907,231.07H100.57v6.319h4.336Zm0,0"
        transform="translate(-97.453 -221.595)"
        fill="#787c80"
      />
      <path
        id="Path_207"
        d="M.861,132.716a.541.541,0,0,1,.18-1.052H8.269a.541.541,0,0,1,.18,1.052v11.626a.541.541,0,0,1-.18,1.052H1.041a.541.541,0,0,1-.18-1.052v-1.687H5.739a.542.542,0,0,0,.541-.541v-7.4a.542.542,0,0,0-.541-.541H.861Zm0,0"
        transform="translate(2.256 -125.777)"
        fill="#787c80"
      />
      <g
        id="Group_865"
        transform="translate(0 0)"
      >
        <line
          id="Line_108"
          x1="5.432"
          y1="1.811"
          fill="none"
          stroke="#787c80"
          strokeWidth="0.5"
        />
        <line
          id="Line_109"
          x1="5.432"
          y1="0.604"
          transform="translate(0 1.207)"
          fill="none"
          stroke="#787c80"
          strokeWidth="0.5"
        />
        <line
          id="Line_110"
          x1="5.432"
          y2="0.604"
          transform="translate(0 1.811)"
          fill="none"
          stroke="#787c80"
          strokeWidth="0.5"
        />
        <line
          id="Line_111"
          x1="5.432"
          y2="1.811"
          transform="translate(0 1.811)"
          fill="none"
          stroke="#787c80"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
</template>