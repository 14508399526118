<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="25"
    viewBox="0 0 28 28"
    fill="#fff"
  ><path
    d="M210.4,113.14H183.864a.734.734,0,0,0-.734.734v26.532a.734.734,0,0,0,.734.734H210.4a.734.734,0,0,0,.734-.734V113.874a.734.734,0,0,0-.734-.734Zm-.734,26.532H184.6V114.608h25.063Zm-14.734-8.523-3,.125a.734.734,0,0,1-.734-.734l.059-3.025,5.917-5.873,3.627,3.59Zm6.93-6.945-3.634-3.671.785-.734a1.469,1.469,0,0,1,2.078,0l1.549,1.556a1.468,1.468,0,0,1,0,2.078Zm-11.4,9.992a.734.734,0,0,1,.734-.734H203.07a.734.734,0,1,1,0,1.468H191.191A.734.734,0,0,1,190.457,134.2Z"
    transform="translate(-183.13 -113.14)"
  /></svg>
</template>