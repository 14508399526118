<template>
  <svg
    id="Group_7075"
    data-name="Group 7075"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <line
      id="Line_353"
      data-name="Line 353"
      x2="12"
      transform="translate(0 6)"
      fill="none"
      stroke="#787c80"
      stroke-width="2"
    />
    <line
      id="Line_354"
      data-name="Line 354"
      y1="12"
      transform="translate(6)"
      fill="none"
      stroke="#787c80"
      stroke-width="2"
    />
  </svg>
</template>