import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from '@/App.vue';
import '@/assets/css/app.css';
import { routes } from '@/router.js';
import { vfmPlugin } from 'vue-final-modal';

const app = createApp(App);
const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('web-');
app.use(router);
app.use(vfmPlugin);
app.mount('#app');
