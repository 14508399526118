import home from './pages/home.vue';
import configurator from './pages/configurator.vue';

export const routes = [
  { path: '/', name: 'home', component: home },
  {
    path: '/configurator/:carId',
    name: 'configurator',
    component: configurator,
  },
  {
    path: '/wheel-zoom/:wheelId',
    name: 'wheel-zoom',
    component: () => import('./pages/wheel-zoom.vue'),
  },
];