<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.4"
    height="16.4"
    viewBox="0 0 19.4 16.4"
  >
    <g
      id="Group_7607"
      transform="translate(-1.347 0.235)"
    >
      <rect
        id="Rectangle_2867"
        width="18"
        height="15"
        transform="translate(2.047 0.465)"
        fill="none"
        stroke="#787c80"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        id="Path_8021"
        d="M153.868,221.24l-3.331,4.482-2.2-1.34-.009-.058L145,226.832v.836h13.3v-1.1l-1.662-3.658Z"
        transform="translate(-140.6 -215.396)"
        fill="#787c80"
      />
      <path
        id="Path_8022"
        d="M169.5,182.147a1.3,1.3,0,1,0-1.3-1.3A1.3,1.3,0,0,0,169.5,182.147Z"
        transform="translate(-162.572 -175.902)"
        fill="#787c80"
      />
    </g>
  </svg>
</template>