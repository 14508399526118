<template>
  <svg
    width="9.801"
    height="11.714"
    viewBox="0 0 9.801 11.714"
  >
    <g
      id="Icon_feather-chevrons-down"
      data-name="Icon"
      feather-chevrons-down
      transform="translate(-9.799 -8.286)"
    >
      <path
        id="Path_6695"
        data-name="Path"
        d="M10.5,19.5l4.2,4.124L18.9,19.5"
        transform="translate(0 -4.624)"
        fill="none"
        :stroke="classFill"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_6696"
        data-name="Path"
        d="M10.5,9l4.2,4.124L18.9,9"
        transform="translate(0 0)"
        fill="none"
        :stroke="classFill"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    classFill: {
      type: String,
      default: '',
    },
  },
};
</script>