<template>
  <div
    :style="{
      height: 'var(--page-height-px)',
      minHeight: 'var(--page-height-px)',
    }"
    class="relative w-full flex flex-col bg-main-bg"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      const { innerHeight } = window;
      const vh = innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--page-height-px', `${vh}px`);
    },
  },
};
</script>
