<template>
  <div class="relative w-full flex flex-col h-full">
    <web-configurator
      id="wcConfigurator"
      :carid="route.params.carId"
      :diameter="selectedWheelDiameter.toString()"
      :wheelid="selectedWheelKey"
      :color="selectedColor"
      :background="selectedBg"
      :susval="selectedSuspension"
      :paint="selectedPaint"
      suspension="true"
      class="absolute w-full h-full z-10"
      autorotate="false"
      onloading="onloading"
      iscustom="true"
    />
    <template v-if="!isLoading && isMobileAndTablet">
      <button
        class="absolute top-4 left-4 p-2 rounded-lg z-10"
        @click="onBack"
      >
        <div class="absolute top-0 left-0 w-full h-full bg-black opacity-40 rounded-lg z-10" />
        <div class="relative z-10">
          <p class="text-[#cccccc] text-xs text-left">
            jfnetwork 3D
          </p>
          <div class="flex items-center">
            <h1 class="text-white font-sofia-bold text-md">
              {{ route.query.name }}
            </h1>
            <icon-change-car class="ml-2 w-[18px] h-[18px]" />
          </div>
        </div>
      </button>

      <div
        ref="mobileBgRef"
        class="absolute bottom-4 left-2 w-12 h-12 z-50"
      >
        <button
          class="absolute top-0 left-0 p-2 rounded-full z-10 bg-white shadow w-12 h-12 flex items-center justify-center"
          @click="onTriggerShowBgMobile"
        >
          <icon-gallery class="h-[21px]" />
        </button>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-show="showBgMobile"
            class="absolute bottom-0 left-16 p-[10px] shadow-sm bg-white border border-[#dcdee0] rounded-lg z-10"
          >
            <p class="text-[13px] font-sofia-semibold pb-2 border-b border-[#dcdee0]">
              Background
            </p>
            <ul class="w-[100px] flex flex-col mt-2 gap-y-2">
              <li
                :class="[
                  'w-full h-14 bg-white border border-[#dcdee0] cursor-pointer',
                  selectedBg === 0 ? 'border-primary pointer-events-none' : ''
                ]"
                @click="onSelectBg(0)"
              />
              <li
                :class="[
                  'w-full h-14 border',
                  selectedBg === 1 ? 'border-primary pointer-events-none' : 'border-transparent'
                ]"
              >
                <button
                  :class="[
                    'w-full h-full transition-opacity ease-in-out duration-300',
                    selectedBg === 1 ? 'opacity-50 pointer-events-none' : 'opacity-100'
                  ]"
                  @click="onSelectBg(1)"
                >
                  <img
                    src="@/assets/cover-canary.jpg"
                    class="w-full h-full object-cover"
                  >
                </button>
              </li>
              <li
                :class="[
                  'w-full h-14 border',
                  selectedBg === 2 ? 'border-primary pointer-events-none' : 'border-transparent'
                ]"
              >
                <button
                  :class="[
                    'w-full h-full transition-opacity ease-in-out duration-300',
                    selectedBg === 2 ? 'opacity-50 pointer-events-none' : 'opacity-100'
                  ]"
                  @click="onSelectBg(2)"
                >
                  <img
                    src="@/assets/cover-el.jpg"
                    class="w-full h-full object-cover"
                  >
                </button>
              </li>
              <li
                :class="[
                  'w-full h-14 border',
                  selectedBg === 3 ? 'border-primary pointer-events-none' : 'border-transparent'
                ]"
              >
                <button
                  :class="[
                    'w-full h-full transition-opacity ease-in-out duration-300',
                    selectedBg === 3 ? 'opacity-50 pointer-events-none' : 'opacity-100'
                  ]"
                  @click="onSelectBg(3)"
                >
                  <img
                    src="@/assets/cover-station.jpg"
                    class="w-full h-full object-cover"
                  >
                </button>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div
        ref="mobileColorRef"
        class="absolute bottom-4 right-2 w-12 h-12 z-50"
      >
        <button
          class="absolute top-0 left-0 p-2 rounded-full z-10 bg-white shadow w-12 h-12 flex items-center justify-center"
          @click="onTriggerShowColorMobile"
        >
          <icon-spray class="h-[21px]" />
        </button>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-show="showColorMobile"
            :style="{
              height: isMobileAndLandscape ? `calc(var(--page-height-px) - 1rem)` : `calc(var(--page-height-px) - var(--page-height-px) / 3)`,
            }"
            class="absolute bottom-0 right-16 py-[10px] px-[16px] flex flex-col items-center shadow-sm bg-white border border-[#dcdee0] rounded-lg z-10"
          >
            <div class="relative w-full h-[26px] min-h-[26px] mb-4">
              <icon-spray class="absolute top-1/2 left-[45%] transform -translate-x-1/2 -translate-y-1/2" />
            </div>
            <div class="flex flex-col w-full h-full justify-between pt-5 border-t border-[#dcdee0]">
              <ul class="flex flex-col items-center w-full gap-y-5">
                <li
                  v-for="(color, index ) in COLOR_LIST"
                  :key="`colorOption${index}`"
                  :class="[
                    'w-4 h-4 rounded-full transition-all ease-in-out duration-300 border border-[#dcdee0] cursor-pointer',
                    color.value === selectedColor ? 'transform scale-150 shadow' : 'transform scale-100'
                  ]"
                  :style="{
                    backgroundColor: color.display
                  }"
                  @click="onSelectColor(color.value)"
                />
              </ul>
            </div>
            <div class="w-full pt-5 border-t border-[#dcdee0]">
              <button
                class="w-full pb-2 flex justify-center"
                @click="triggerShowColorPicker"
              >
                <icon-plus />
              </button>
            </div>
            <div
              ref="colorPickerRef"
              :class="[
                'absolute w-[210px] p-[8px] right-full bottom-0 transform -translate-x-4 rounded-lg shadow-sm bg-white border border-[#dcdee0] transition-opacity ease-in-out duration-300',
                showColorPicker ? 'opacity-100 visible' : 'opacity-0 invisible'
              ]"
            >
              <color-picker
                :color="selectedColor"
                :visible-formats="['hex']"
                :style="{
                  '--vacp-border-color': '#dcdee0'
                }"
                class="w-full"
                default-format="hex"
                @color-change="updateColor"
              />
              <div class="mt-1 grid grid-cols-2 items-center justify-center">
                <div
                  class="text-center text-[10px] border-r border-[#dcdee0] py-1 cursor-pointer"
                  @click="selectedPaint = 0"
                >
                  <span
                    :class="[
                      'relative before:absolute before:top-full before:w-full before:h-px before:transfrom before:translate-y-1 before:bg-[#dcdee0] before:transition-opacity before:ease-in-out before:duration-300',
                      selectedPaint === 0 ? 'before:opacity-100' : 'before:opacity-0'
                    ]"
                  >
                    GLOSS
                  </span>
                </div>
                <div
                  class="text-center text-[10px] py-1 cursor-pointer"
                  @click="selectedPaint = 1"
                >
                  <span
                    :class="[
                      'relative before:absolute before:top-full before:w-full before:h-px before:transfrom before:translate-y-1 before:bg-[#dcdee0] before:transition-opacity before:ease-in-out before:duration-300',
                      selectedPaint === 1 ? 'before:opacity-100' : 'before:opacity-0'
                    ]"
                  >
                    MATTE
                  </span>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div
        ref="mobileSuspensionRef"
        class="absolute bottom-[75px] right-2 z-50 w-12 h-12"
      >
        <button
          class="absolute top-0 left-0 p-2 rounded-full z-10 bg-white shadow w-12 h-12 flex items-center justify-center"
          @click="onTriggerShowSuspensionMobile"
        >
          <icon-suspension class="transform rotate-90" />
        </button>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-show="showSuspensionMobile"
            :style="{
              height: isMobileAndLandscape ? `calc(var(--page-height-px) - 1rem)` : `calc(var(--page-height-px) - var(--page-height-px) / 3)`,
            }"
            class="absolute bottom-[-59px] right-16 py-[10px] px-[16px] flex flex-col items-center shadow-sm bg-white border border-[#dcdee0] rounded-lg z-10"
          >
            <div class="relative h-[26px] min-h-[26px] mb-4">
              <icon-suspension class="absolute top-1/2 left-1/8 transform -translate-x-1/2 -translate-y-1/2 rotate-90" />
            </div>
            <div class="flex flex-col items-center gap-y-4 w-full h-full pt-5 border-t border-[#dcdee0]">
              <button
                class="w-full h-6 flex items-center justify-center"
                @click="raiseSuspension"
              >
                <icon-plus />
              </button>
              <vue3-slider
                v-model="selectedSuspension"
                :min="0"
                :max="10"
                :step="1"
                :handle-scale="3"
                color="#FB278D"
                track-color="#FEFEFE"
                orientation="vertical"
                always-show-handle
              />
              <button
                class="w-full h-6 flex items-center justify-center"
                @click="lowerSuspension"
              >
                <icon-minus />
              </button>
            </div>
          </div>
        </transition>
      </div>
    </template>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoadingAfterFinishDelay"
        class="absolute top-0 left-0 w-full h-full flex items-center justify-center z-10 filter backdrop-blur-[40px]"
      >
        <img
          src="@/assets/jflogo.svg"
          class="w-[20%] h-auto object-contain"
        >
      </div>
    </transition>
    <div
      v-if="!isMobileAndTablet"
      :style="{
        height: 'var(--page-height-px)',
        gridTemplateColumns: '140px calc(100% - 280px - 2rem) 140px'
      }"
      class="relative w-full grid gap-x-4 px-4"
    >
      <div
        v-if="!isLoading"
        class="relative h-[75%] mt-4 px-[18px] shadow-sm bg-white border border-[#dcdee0] rounded-lg align-self-center z-[11]"
      >
        <p class="text-[13px] font-sofia-semibold mt-4 pb-4 border-b border-[#dcdee0]">
          Background
        </p>
        <ul class="flex flex-col mt-2 gap-y-2">
          <li
            :class="[
              'w-full h-14 bg-white border border-[#dcdee0] cursor-pointer',
              selectedBg === 0 ? 'border-primary pointer-events-none' : ''
            ]"
            @click="onSelectBg(0)"
          />
          <li
            :class="[
              'w-full h-14 border',
              selectedBg === 1 ? 'border-primary pointer-events-none' : 'border-transparent'
            ]"
          >
            <button
              :class="[
                'w-full h-full transition-opacity ease-in-out duration-300',
                selectedBg === 1 ? 'opacity-50 pointer-events-none' : 'opacity-100'
              ]"
              @click="onSelectBg(1)"
            >
              <img
                src="@/assets/cover-canary.jpg"
                class="w-full h-full object-cover"
              >
            </button>
          </li>
          <li
            :class="[
              'w-full h-14 border',
              selectedBg === 2 ? 'border-primary pointer-events-none' : 'border-transparent'
            ]"
          >
            <button
              :class="[
                'w-full h-full transition-opacity ease-in-out duration-300',
                selectedBg === 2 ? 'opacity-50 pointer-events-none' : 'opacity-100'
              ]"
              @click="onSelectBg(2)"
            >
              <img
                src="@/assets/cover-el.jpg"
                class="w-full h-full object-cover"
              >
            </button>
          </li>
          <li
            :class="[
              'w-full h-14 border',
              selectedBg === 3 ? 'border-primary pointer-events-none' : 'border-transparent'
            ]"
          >
            <button
              :class="[
                'w-full h-full transition-opacity ease-in-out duration-300',
                selectedBg === 3 ? 'opacity-50 pointer-events-none' : 'opacity-100'
              ]"
              @click="onSelectBg(3)"
            >
              <img
                src="@/assets/cover-station.jpg"
                class="w-full h-full object-cover"
              >
            </button>
          </li>
        </ul>
      </div>
      <div
        :style="{
          height: 'var(--page-height-px)',
        }"
        class="relative w-full h-full min-h-full col-start-2"
      >
        <button
          v-if="!isLoading"
          class="absolute top-4 left-4 p-4 rounded-lg"
          @click="onBack"
        >
          <div class="absolute top-0 left-0 w-full h-full bg-black opacity-40 rounded-lg z-10" />
          <div class="relative z-10">
            <p class="text-[#cccccc] text-sm text-left">
              jfnetwork 3D
            </p>
            <div class="flex items-center">
              <h1 class="text-white font-sofia-bold text-lg">
                {{ route.query.name }}
              </h1>
              <icon-change-car class="ml-4" />
            </div>
          </div>
        </button>
      </div>
      <div class="relative grid grid-cols-2 gap-x-4 h-[75%] mt-4 align-self-center z-[11]">
        <template v-if="!isLoading">
          <div
            class="relative py-[10px] px-[8px] flex flex-col items-center h-full shadow-sm bg-white border border-[#dcdee0] rounded-lg"
          >
            <div class="relative w-full h-[26px] min-h-[26px] mb-4">
              <icon-spray class="absolute top-1/2 left-[45%] transform -translate-x-1/2 -translate-y-1/2" />
            </div>
            <div class="flex flex-col w-full h-full justify-between pt-5 border-t border-[#dcdee0]">
              <ul class="flex flex-col items-center w-full gap-y-5">
                <li
                  v-for="(color, index ) in COLOR_LIST"
                  :key="`colorOption${index}`"
                  :class="[
                    'w-4 h-4 rounded-full transition-all ease-in-out duration-300 border border-[#dcdee0] cursor-pointer',
                    color.value === selectedColor ? 'transform scale-150 shadow' : 'transform scale-100'
                  ]"
                  :style="{
                    backgroundColor: color.display
                  }"
                  @click="onSelectColor(color.value)"
                />
              </ul>
            </div>
            <div class="w-full pt-5 border-t border-[#dcdee0]">
              <button
                class="w-full pb-2 flex justify-center"
                @click="triggerShowColorPicker"
              >
                <icon-plus />
              </button>
            </div>
            <div
              ref="colorPickerRef"
              :class="[
                'absolute w-[210px] p-[8px] right-full bottom-0 transform -translate-x-4 rounded-lg shadow-sm bg-white border border-[#dcdee0] transition-opacity ease-in-out duration-300',
                showColorPicker ? 'opacity-100 visible' : 'opacity-0 invisible'
              ]"
            >
              <color-picker
                :color="selectedColor"
                :visible-formats="['hex']"
                :style="{
                  '--vacp-border-color': '#dcdee0'
                }"
                class="w-full"
                default-format="hex"
                @color-change="updateColor"
              />
              <div class="mt-1 grid grid-cols-2 items-center justify-center">
                <div
                  class="text-center text-[10px] border-r border-[#dcdee0] py-1 cursor-pointer"
                  @click="selectedPaint = 0"
                >
                  <span
                    :class="[
                      'relative before:absolute before:top-full before:w-full before:h-px before:transfrom before:translate-y-1 before:bg-[#dcdee0] before:transition-opacity before:ease-in-out before:duration-300',
                      selectedPaint === 0 ? 'before:opacity-100' : 'before:opacity-0'
                    ]"
                  >
                    GLOSS
                  </span>
                </div>
                <div
                  class="text-center text-[10px] py-1 cursor-pointer"
                  @click="selectedPaint = 1"
                >
                  <span
                    :class="[
                      'relative before:absolute before:top-full before:w-full before:h-px before:transfrom before:translate-y-1 before:bg-[#dcdee0] before:transition-opacity before:ease-in-out before:duration-300',
                      selectedPaint === 1 ? 'before:opacity-100' : 'before:opacity-0'
                    ]"
                  >
                    MATTE
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="py-[10px] px-[8px] flex flex-col items-center h-full shadow-sm bg-white border border-[#dcdee0] rounded-lg">
            <div class="relative h-[26px] min-h-[26px] mb-4">
              <icon-suspension class="absolute top-1/2 left-1/8 transform -translate-x-1/2 -translate-y-1/2 rotate-90" />
            </div>
            <div class="flex flex-col items-center gap-y-4 w-full h-full pt-5 border-t border-[#dcdee0]">
              <button
                class="w-full h-6 flex items-center justify-center"
                @click="raiseSuspension"
              >
                <icon-plus />
              </button>
              <vue3-slider
                v-model="selectedSuspension"
                :min="0"
                :max="10"
                :step="1"
                :handle-scale="3"
                color="#FB278D"
                track-color="#FEFEFE"
                orientation="vertical"
                always-show-handle
              />
              <button
                class="w-full h-6 flex items-center justify-center"
                @click="lowerSuspension"
              >
                <icon-minus />
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      ref="wheelListRef"
      :class="[
        'absolute left-0 bottom-0 w-full p-4 z-[51] transition-all ease-[cubic-bezier(0.16,1,0.3,1)] duration-300 bg-white/40 filter backdrop-blur-lg',
        isMobileAndTablet
          ? isMobileAndLandscape
            ? openWheelList ? 'transform translate-y-[120px] z-50' : 'transform translate-y-full'
            : openWheelList ? 'transform translate-y-0 z-50' : 'transform translate-y-full'
          : openWheelList ? 'transform translate-y-0 z-50' : 'transform translate-y-[500px]',
        isMobileAndTablet
          ? isMobileAndLandscape
            ? 'h-full'
            : 'h-[325px] pt-[50px]'
          : 'h-[275px] pt-[50px]'
      ]"
    >
      <carousel
        v-if="isMobileAndTablet"
        :items-to-show="isMobileAndLandscape ? 2.5 : 1.5"
        :wrap-around="true"
        :class="[isMobileAndLandscape ? 'landscape' : null]"
      >
        <slide
          v-for="(wheel, index) in filteredWheelDiameter"
          :key="`mobile${wheel.key}${index}`"
        >
          <div
            :class="[
              'relative w-[220px] h-[205px] flex flex-col justify-between pt-2 pb-2 pl-4 pr-4 rounded-[14px] shadow bg-white transition-all ease-in-out duration-200',
            ]"
          >
          <button class="absolute top-0 left-0 w-full h-full z-10"
          @click="onSelectWheel(wheel)"
          ></button>
            <button
              class="absolute bottom-3 right-4 z-20"
              @click="onZoomWheel(wheel)"
            >
              <icon-threesixty />
            </button>
            <p class="text-[14px] font-sofia-bold">
              {{ wheel.brand }}
            </p>
            <div class="absolute top-8 left-0 w-full h-[100px] flex justify-center">
              <img
                :src="wheel.img"
                class="w-[90%] h-[130px] trasform translate-y-[-5px] object-contain object-center"
              >
            </div>
            <ul
              :class="[
                'absolute top-8 right-[14px] flex flex-col items-center gap-y-2 z-10',
                isLoading ? 'pointer-events-none' : null
              ]"
            >
              <li
                v-for="(diameter, diameterIndex) in wheel.foundedDiameters"
                :key="`wheelDiameter${wheel.brand}${wheel.design}${wheel.color}${diameterIndex}`"
                :class="[
                  'relative cursor-pointer',
                  'before:absolute before:top-1/2 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2 before:bg-primary before:w-[36px] before:h-[30px] before:rounded-md before:z-[-1]',
                  selectedWheelKey === wheel.key && selectedWheelDiameter.toString() === diameter.toString()
                    ? 'before:opacity-100 text-white'
                    : 'before:opacity-0'
                ]"
                @click="onSelectDiameterCard(wheel.key, diameter)"
              >
                {{ diameter }}
              </li>
            </ul>
            <div>
              <p class="font-sofia-bold text-[16px] leading-none">
                {{ wheel.design }}
              </p>
              <p class="font-sofia-semibold text-[13px] text-[#9c9ea1] whitespace-nowrap">
                {{ wheel.color }}
              </p>
            </div>
          </div>
        </slide>

        <template #addons>
          <Navigation />
        </template>
      </carousel>
      <ul
        v-else
        class="w-full flex justify-center gap-x-3"
      >
        <li
          v-for="(wheel, index) in filteredWheelDiameter"
          :key="`${wheel.key}${index}`"
          :class="[
            'relative w-[16.6%] h-[205px] flex flex-col justify-between pt-2 pb-2 pl-4 pr-4 rounded-[14px] shadow filter backdrop-blur-md bg-white/90 transition-all ease-in-out duration-200',
            'cursor-pointer'
          ]"
        >
          <button class="absolute top-0 left-0 w-full h-full z-10"
          @click="onSelectWheel(wheel)"
          ></button>
          <button
            class="absolute bottom-3 right-4 z-20"
            @click="onZoomWheel(wheel)"
          >
            <icon-threesixty />
          </button>
          <p class="text-[14px] font-sofia-bold">
            {{ wheel.brand }}
          </p>
          <div class="absolute top-8 left-0 w-full h-[100px] flex justify-center">
            <img
              :src="wheel.img"
              class="w-[90%] h-[130px] trasform translate-y-[-5px] object-contain object-center"
            >
          </div>
          <ul
            :class="[
              'absolute top-8 right-[14px] flex flex-col items-center gap-y-2 z-10',
              isLoading ? 'pointer-events-none' : null
            ]"
          >
            <li
              v-for="(diameter, diameterIndex) in wheel.foundedDiameters"
              :key="`wheelDiameter${wheel.brand}${wheel.design}${wheel.color}${diameterIndex}`"
              :class="[
                'relative cursor-pointer',
                'before:absolute before:top-1/2 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2 before:bg-primary before:w-[36px] before:h-[30px] before:rounded-md before:z-[-1]',
                selectedWheelKey === wheel.key && selectedWheelDiameter.toString() === diameter.toString()
                  ? 'before:opacity-100 text-white'
                  : 'before:opacity-0'
              ]"
              @click="onSelectDiameterCard(wheel.key, diameter)"
            >
              {{ diameter }}
            </li>
          </ul>
          <div>
            <p class="font-sofia-bold text-[16px] leading-none">
              {{ wheel.design }}
            </p>
            <p class="font-sofia-semibold text-[13px] text-[#9c9ea1] whitespace-nowrap">
              {{ wheel.color }}
            </p>
          </div>
        </li>
      </ul>
      <button
        v-if="!isLoading"
        :class="[
          'absolute left-1/2 top-2 p-2 transform -translate-x-1/2 z-40 flex items-center transition-all ease-[cubic-bezier(0.16,1,0.3,1)] duration-300',
          'group font-sofia-bold',
          selectedBg === 3 || selectedBg === 2 ? 'text-white' : 'text-[#23272B]'
        ]"
        @click="openWheelList = false"
      >
        HIDE WHEELS <icon-caret
          :class="[
            'ml-1 transition-all ease-[cubic-bezier(0.16,1,0.3,1)] duration-300 group-hover:transform group-hover:translate-y-1',
            'transform rotate-0'
          ]"
          :class-fill="selectedBg === 3 || selectedBg === 2 ? '#ffffff' : '#23272B'"
        />
      </button>
    </div>
    <button
      v-if="!isLoading && !openWheelList"
      :class="[
        'absolute left-1/2 bottom-2 tablet:bottom-2 p-2 transform -translate-x-1/2 z-40 flex items-center transition-all ease-[cubic-bezier(0.16,1,0.3,1)] duration-300',
        'group font-sofia-bold text-[12px] tablet:text-[16px]',
        selectedBg === 3 || selectedBg === 2 ? 'text-white' : 'text-[#23272B]'
      ]"
      @click="openWheelList = true"
    >
      SHOW WHEELS <icon-caret
        :class="[
          'ml-1 transition-all ease-[cubic-bezier(0.16,1,0.3,1)] duration-300 group-hover:transform group-hover:-translate-y-1',
          'transform rotate-180',
        ]"
        :class-fill="selectedBg === 3 || selectedBg === 2 ? '#ffffff' : '#23272B'"
      />
    </button>
  </div>
  <vue-final-modal
    v-slot="{ close }"
    v-model="showModal"
    name="example"
    classes="flex justify-center items-center"
    content-class="relative flex flex-col max-h-full mx-4 p-4 border dark:border-gray-800 rounded bg-white dark:bg-gray-900 min-w-[85%] w-[85%] min-h-[90%] h-[90%]"
  >
    <web-configurator-wheelzoom
      id="wcConfiguratorWheelzoom"
      class="w-full h-full"
      :wheelid="selectedWheelKey"
      onloading="onloading"
    />
    <button
      class="absolute top-0 right-0 px-2 text-[35px]"
      @click="close"
    >
      &times;
    </button>
  </vue-final-modal>
</template>

<script>
import IconCaret from '../components/IconCaret';
import IconChangeCar from '../components/IconChangeCar';
import IconGallery from '../components/IconGallery';
import IconSpray from '../components/IconSpray';
import IconSuspension from '../components/IconSuspension';
import IconThreesixty from '../components/IconThreesixty';
import IconPlus from '../components/IconPlus';
import IconMinus from '../components/IconMinus';

import Vue3Slider from 'vue3-slider';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { ColorPicker } from 'vue-accessible-color-picker';

import { useWindowSize, useScreenOrientation, onClickOutside } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue';

export default {
  components: {
    IconChangeCar,
    IconGallery,
    IconSpray,
    IconSuspension,
    IconThreesixty,
    IconPlus,
    IconMinus,
    Vue3Slider,
    ColorPicker,
    IconCaret,
    Carousel,
    Slide,
    Navigation,

  },
  setup() {
    const route = useRoute();
    const { width } = useWindowSize();
    const { orientation } = useScreenOrientation();
    const colorPickerRef = ref(null);
    const wheelListRef = ref(null);

    const mobileBgRef = ref(null);
    const mobileColorRef = ref(null);
    const mobileSuspensionRef = ref(null);

    const showColorPicker = ref(false);
    const openWheelList = ref(false);
    const showBgMobile = ref(false);
    const showColorMobile = ref(false);
    const showSuspensionMobile = ref(false);

    const isMobileAndLandscape = computed(() => width.value < 900 && orientation.value.includes('landscape'));

    const COLOR_LIST = [
      {
        display: '#0c0c45',
        value: '#010309',
      },
      {
        display: '#568364',
        value: '#082a10',
      },
      {
        display: '#7A0200',
        value: '#7A0200',
      },
      {
        display: '#646a74',
        value: '#19191A',
      },
      {
        display: '#000000',
        value: '#000000',
      },
      {
        display: '#ffffff',
        value: '#ffffff',
      },
    ];

    const WHEEL_OPTIONS_LIST = [
      {
        key: 'itwheels-amos',
        design: 'AMOS',
        brand: 'itWheels',
        color: 'Gloss Black Polished',
        diameter: [17,18,19,20],
        img: require('@/assets/amos.jpg'),
      },
      {
        key: 'bbs-lm',
        design: 'LM',
        brand: 'BBS',
        color: 'Brilliant silver',
        img: require('@/assets/lm.jpg'),
        diameter: [16,17,18],
      },
      {
        key: 'bbs-chr',
        design: 'CH-R II',
        brand: 'BBS',
        color: 'Satin Bronze',
        img: require('@/assets/chr.jpg'),
        diameter: [19,20,21],
      },
      {
        key: 'bbs-cir',
        design: 'CI-R',
        brand: 'BBS',
        color: 'Nürburgring Edition',
        img: require('@/assets/cir.jpg'),
        diameter: [19,20,21],
      },
      {
        key: 'itwheels-michelle',
        design: 'MICHELLE',
        brand: 'itWheels',
        color: 'Matt Black',
        img: require('@/assets/michelle.jpg'),
        diameter: [16,17,18,19,20],
      },
      {
        key: 'itwheels-emily',
        design: 'EMILY',
        brand: 'itWheels',
        color: 'Gloss Silver',
        img: require('@/assets/emily.jpg'),
        diameter: [16,17,18],
      },
    ];

    function triggerShowColorPicker() {
      showColorPicker.value = !showColorPicker.value;
    }
    function closeColorPicker() {
      showColorPicker.value = false;
    }
    function closeWheelList() {
      openWheelList.value = false;
    }
    function closeBgMobile(event) {
      showBgMobile.value = false;
    }
    function closeColorMobile(event) {
      showColorMobile.value = false;
    }
    function closeSuspensionMobile() {
      showSuspensionMobile.value = false;
    }

    onClickOutside(colorPickerRef, closeColorPicker);
    onClickOutside(wheelListRef, closeWheelList);
    onClickOutside(mobileBgRef, closeBgMobile);
    onClickOutside(mobileColorRef, closeColorMobile);
    onClickOutside(mobileSuspensionRef, closeSuspensionMobile);

    return {
      route,
      width,
      WHEEL_OPTIONS_LIST,
      COLOR_LIST,
      triggerShowColorPicker,
      closeColorPicker,
      showColorPicker,
      colorPickerRef,
      wheelListRef,
      openWheelList,
      isMobileAndLandscape,
      mobileBgRef,
      mobileColorRef,
      mobileSuspensionRef,
      showBgMobile,
      showColorMobile,
      showSuspensionMobile,
    };
  },
  data() {
    return {
      webComponentEl: null,
      webComponentZoomEl: null,
      isLoading: false,
      isLoadingWheelZoom: false,
      isLoadingAfterFinishDelay: false,
      selectedWheelKey: 'itwheels-amos',
      selectedWheelDiameter: 18,
      selectedBg: 0,
      selectedPaint: 0,
      selectedColor: '#7A0200',
      selectedSuspension: 10,

      showModal: false,
    };
  },
  computed: {
    isMobileAndTablet() {
      return this.width < 900;
    },
    carDiameters() {
      if (!this.$route.query.diameters) {
        return [];
      }
      return this.$route.query.diameters.split(',').map((d) => parseInt(d));
    },
    filteredWheelDiameter() {
      return this.WHEEL_OPTIONS_LIST.slice()
        .filter((wheel) => this.carDiameters.some((carDiameter) => wheel.diameter.includes(carDiameter)))
        .map((filteredWheel) => {
          return {
            ...filteredWheel,
            foundedDiameters: filteredWheel.diameter.filter((item) => this.carDiameters.includes(item)),
          };
        });
    },
  },
  watch: {
    isLoading(val) {
      if (val) {
        this.isLoadingAfterFinishDelay = val;
      } else {
        this.setIsLoadingAfterFinishDelay();
      }
    },
  },
  created() {
    this.selectedWheelKey = this.filteredWheelDiameter[0].key;
  },
  mounted() {
    this.webComponentZoomEl = document.getElementById('wcConfiguratorWheelzoom');
    if (this.webComponentZoomEl) {
      this.webComponentZoomEl.addEventListener('onloading', this.setIsLoadingWheelZoom);
    }

    if (this.route.query.baseDiameter) {
      if (this.filteredWheelDiameter[0].foundedDiameters.includes(parseInt(this.route.query.baseDiameter))) {
        this.selectedWheelDiameter = this.route.query.baseDiameter;
      } else {
        this.selectedWheelDiameter = this.filteredWheelDiameter[0].foundedDiameters[0];
      }
    }
    this.webComponentEl = document.getElementById('wcConfigurator');
    if (this.webComponentEl) {
      this.webComponentEl.addEventListener('onloading', this.setIsLoading);
    }
  },
  methods: {
    setIsLoading(e) {
      this.isLoading = e.detail;
    },
    setIsLoadingWheelZoom(e) {
      this.isLoading = e.detail;
    },
    setIsLoadingAfterFinishDelay(e) {
      this.isLoadingAfterFinishDelay = true;
      setTimeout(() => {
        this.isLoadingAfterFinishDelay = false;
      }, 200);
    },
    onSelectWheel(wheel) {
      if (this.selectedWheelKey !== wheel.key) {
        this.isLoadingAfterFinishDelay = true;
        this.selectedWheelDiameter = wheel.foundedDiameters[0];
      }
      this.openWheelList = false;
      this.selectedWheelKey = wheel.key;
    },
    onSelectBg(index) {
      this.showBgMobile = false;
      this.selectedBg = index;
    },
    onSelectColor(color) {
      this.selectedColor = color;
    },
    onBack() {
      this.$router.push({ name: 'home' });
    },
    onSelectDiameterCard(wheelKey, diameter) {
      this.openWheelList = false;
      this.selectedWheelKey = wheelKey;
      this.selectedWheelDiameter = diameter;
    },
    onTriggerShowBgMobile() {
      this.showBgMobile = !this.showBgMobile;
    },
    onTriggerShowColorMobile() {
      this.showColorMobile = !this.showColorMobile;
    },
    onTriggerShowSuspensionMobile() {
      this.showSuspensionMobile = !this.showSuspensionMobile;
    },
    onZoomWheel(wheel) {
      this.selectedWheelKey = wheel.key;
      setTimeout(() => {
        this.showModal = true;
      }, 200);
    },
    updateColor(color) {
      this.selectedColor = color.colors.hex.slice(0, 7);
    },
    raiseSuspension() {
      if (this.selectedSuspension === 10) {
        return;
      }
      this.selectedSuspension++;
    },
    lowerSuspension() {
      if (this.selectedSuspension === 0) {
        return;
      }
      this.selectedSuspension--;
    },
  },
};
</script>