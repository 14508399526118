<template>
  <svg
    id="Group_863"
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 15.695 15.695"
  >
    <path
      id="Path_199"
      d="M290.289,0a2.286,2.286,0,0,1,2.24,2.758l1.119,1.119.789-.263a.327.327,0,1,1,.207.62l-3.924,1.308a.327.327,0,1,1-.207-.62l.479-.16-.233-.233A2.289,2.289,0,1,1,290.289,0Zm0,3.27a.981.981,0,1,0-.981-.981A.982.982,0,0,0,290.289,3.27Z"
      transform="translate(-288)"
      fill="#787c80"
    />
    <path
      id="Path_200"
      d="M160.224,246.659l.514-.171a.32.32,0,0,1-.022-.195.327.327,0,0,1,.215-.235l1.962-.654a.327.327,0,0,1,.335.079l.131.131.789-.263a.327.327,0,1,1,.207.62l-3.924,1.308a.327.327,0,0,1-.207-.62Z"
      transform="translate(-153.788 -237.813)"
      fill="#787c80"
    />
    <path
      id="Path_201"
      d="M202.89,203.984l.514-.171a.32.32,0,0,1-.022-.195.327.327,0,0,1,.215-.235l1.962-.654a.324.324,0,0,1,.335.079l.131.131.789-.263a.327.327,0,1,1,.207.62L203.1,204.6a.327.327,0,0,1-.207-.62Z"
      transform="translate(-197.762 -196.446)"
      fill="#787c80"
    />
    <path
      id="Path_202"
      d="M245.557,161.313l.514-.171a.32.32,0,0,1-.022-.195.327.327,0,0,1,.215-.235l1.962-.654a.327.327,0,0,1,.335.079l.131.131.789-.263a.327.327,0,1,1,.207.62l-3.924,1.308a.327.327,0,1,1-.207-.62Z"
      transform="translate(-241.737 -155.083)"
      fill="#787c80"
    />
    <path
      id="Path_203"
      d="M.224,278.384l1.962-.654a.325.325,0,0,1,.335.079l.043.043.423-.423a.327.327,0,0,1,.462,0l1.635,1.635a.321.321,0,0,1,.089.264,2.609,2.609,0,1,1-2.9,2.9.326.326,0,0,1-.032.006.326.326,0,0,1-.231-.1L.37,280.507a.327.327,0,0,1,0-.462l.423-.423-.7-.7a.327.327,0,0,1,.128-.541Zm4.628,4.835a1.308,1.308,0,1,0-1.308-1.308A1.309,1.309,0,0,0,4.852,283.219Z"
      transform="translate(8.228 -268.831)"
      fill="#787c80"
    />
  </svg>
</template>